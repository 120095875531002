import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import PaymentForm from './PaymentForm';

const SubscriptionDialog = ({ open, onClose }) => {
  let {paymentModal, setPaymentmodal} = useContext(AuthContext);
  const [subscriptionPlan, setSubscriptionplan] = React.useState(1);
  
  const handleClose = () => {
    setPaymentmodal(false);
  };
  
  const handlePaymentOption = (event, newOption) => {
      setSubscriptionplan(newOption);
  };

  return (
    <Dialog open={paymentModal} onClose={handleClose}>
      {/* <DialogTitle>{"Subscribe"}</DialogTitle> */}
      <DialogContent>
        <DialogContentText style={{color:'#006A4E', fontWeight: 'bold', fontSize:20}}>
          Join us today and start your journey with intelligent investing.
          Choose your subscription plan:
        </DialogContentText>
        <ToggleButtonGroup
          value={subscriptionPlan}
          exclusive
          onChange={handlePaymentOption}
        >
          <ToggleButton value={1}>Monthly - Rs. 249</ToggleButton>
          <ToggleButton value={2}>Three Months - Rs. 699</ToggleButton>
          <ToggleButton value={3}>Annual - Rs. 2499</ToggleButton>
        </ToggleButtonGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        {/* <Button style={{backgroundColor: 'darkblue',
        color: 'white'}}>
          Subscribe
        </Button> */}
        <PaymentForm subscriptionid={subscriptionPlan}/>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionDialog;
