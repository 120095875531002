import React from 'react';
import ReactDOM from 'react-dom';
import Home from './Home';

ReactDOM.render(
  <div>     
     <Home />
  </div>,
  document.getElementById('root')
);
