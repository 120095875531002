import { IconButton } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DoubleArrow, Launch, TrendingUp } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useContext, useState } from 'react';
import AuthContext from '../context/AuthContext';
import SimpleTabs from './SimpleTabs';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStylesCard = makeStyles({
  root: {
    minWidth: 200,
    // height: 450,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {/* {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null} */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
  
const FavoriteCard = ({ id, pubdate, title, newsart, symbols, topcompanies, link }) => {
  let {authTokens, serverurl} = useContext(AuthContext)
  const classes = useStylesCard();

  const initialgraph = {};
  topcompanies.forEach((company) => {
    initialgraph[company] = {"nodes":[],"links":[]};
    initialgraph["global"] = {"nodes":[],"links":[]};
  });

  const [open, setOpen] = useState(false);
  const [graph, setGraph] = useState(initialgraph);
  const [cardsaved, setCardsaved] = useState(false);
  const [stockview, setStockview] = useState(1);

  const handleViewtype = (event, newView) => {
    setStockview(newView);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
    if (graph["global"]["nodes"].length === 0) {
      cardgraph();
    }
  };

  const authbearer = {'Authorization':'Bearer ' + String(authTokens.access)}

  const deleteCard = async () => {
    const res = await fetch(serverurl+`/deletecard/` + id,{headers:authbearer});
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    else {
      setCardsaved(true);
    }
  }

  const cardgraph = async () => {
    const res = await fetch(serverurl+`/usercardgraph/` + id+`/`,{headers:authbearer});
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    else {
      const aa = await res.json();
      setGraph(aa);
    }
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {pubdate}
        </Typography>
        <Typography variant="h6" component="h2" style={{ fontSize: 15 }}>
          {/* <ConceptTagger doctext={title} spans={cnpts} /> */}
          <Link href={link} target="_blank">
            {title}
          </Link>
        </Typography>
        <p style={{ lineHeight: 1.5 }}>
          {/* <ConceptTagger doctext={newsart} 
                      spans={cnpts} />               */}
          {newsart}
        </p>
        {topcompanies.slice(0, 4).map((company, i) => (
          <Chip label={company} size="small" />
        ))}
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" variant="outlined" startIcon={<Launch />} onClick={handleToggle}>
          {/* <TextHighlight text={topcompany} /> */}
          Impact
        </Button>
        <IconButton size="small" color="primary" variant="outlined" disabled={cardsaved} style={{marginLeft: "auto"}}>
          <DeleteIcon onClick={deleteCard}/>
        </IconButton>
      </CardActions>
      <Dialog onClose={handleClose} aria-labelledby="max-width-dialog-title"
        open={open} fullWidth={true} maxWidth="xl">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" component="h2" style={{ fontSize: 15 }}>
                <Link href={link} target="_blank">
                  {title}
                </Link>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="h7">
                {newsart}
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* <Typography>
                  {title}
                </Typography> */}
        </DialogTitle>
        <DialogContent dividers disableScrollLock={true} style={{height:"400px"}}>
          <SimpleTabs graph={graph} topcompanies={topcompanies} viewoption={stockview} symbols={symbols}/>
        </DialogContent>
        <DialogActions>
        <ToggleButtonGroup value={stockview} exclusive onChange={handleViewtype}>
          <ToggleButton value={1}>Impact Graph <DoubleArrow /></ToggleButton>
          <ToggleButton value={2}>Price Chart <TrendingUp /></ToggleButton>
          <ToggleButton value={3}>Fundamentals</ToggleButton>
          <ToggleButton value={4}>Technicals</ToggleButton>
          <ToggleButton value={5}>Company Profile</ToggleButton>
        </ToggleButtonGroup>
        </DialogActions>
      </Dialog>

    </Card>
  )
}

export default FavoriteCard
