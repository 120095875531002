import jwt_decode from "jwt-decode";
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({children}) => {
    let [authTokens, setAuthTokens] = useState(()=> localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    let [user, setUser] = useState(()=> localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null)
    let [loading, setLoading] = useState(true)
    let [newsource, setNewsource] = useState(10);
    let [newsfeed, setNewsfeed] = useState("economictimes");
    let [paymentModal, setPaymentmodal] = useState(false);
    let [registerModal, setRegistermodal] = useState(false);
    let [screener,setScreener] = useState(false);

    const serverurl = 'https://causalityarrow.com/cuser'

    const navigate = useNavigate()

    let loginUser = async (e )=> {
        e.preventDefault()
        let response = await fetch(serverurl.concat('','/token/'), {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'username':e.target.username.value, 'password':e.target.password.value})
        })
        let data = await response.json()

        if(response.status === 200){
            setAuthTokens(data)
            setUser(jwt_decode(data.access))
            console.log(jwt_decode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
            navigate('/')
        }else{
            alert('Something went wrong!')
        }
    }

    let loginFreeUser = async ()=> {
        let response = await fetch(serverurl.concat('','/freeuser/'), {
            method:'GET',
            headers:{
                'Content-Type':'application/json'
            },
        })
        let data = await response.json()

        if(response.status === 200){
            setAuthTokens(data)
            setUser(jwt_decode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
            setLoading(false)
            navigate('/')
        }else{
            alert('Something went wrong!')
        }
    }

    let logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        navigate('/home')
    }

    let updateToken = async ()=> {
        let response = await fetch(serverurl.concat('','/token/refresh/'), {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'refresh':authTokens?.refresh})
        })

        let data = await response.json()
        
        if (response.status === 200){
            setAuthTokens(data);
            setUser(jwt_decode(data.access));
            localStorage.setItem('authTokens', JSON.stringify(data));
            await new Promise(resolve => setTimeout(resolve, 1000));
            setLoading(false);
        }else{
            logoutUser()
        }
    }

    let contextData = {
        user:user,
        authTokens:authTokens,
        loginUser:loginUser,
        logoutUser:logoutUser,
        newsource:newsource, 
        setNewsource:setNewsource,
        newsfeed:newsfeed, 
        setNewsfeed:setNewsfeed,
        loading: loading,
        setLoading: setLoading,
        screener: screener,
        setScreener: setScreener,
        serverurl: serverurl,
        paymentModal: paymentModal,
        setPaymentmodal: setPaymentmodal,
        registerModal: registerModal,
        setRegistermodal: setRegistermodal,
        updateToken: updateToken,
    }

    useEffect(()=> {
        if(authTokens) {
            if(loading){
                updateToken();
            }
        }
        else {
            loginFreeUser();
            return;
        }

        let fourMinutes = 1000 * 60 * 20

        let interval =  setInterval(()=> {
            if(authTokens){
                updateToken()
            }
        }, fourMinutes)
        return ()=> clearInterval(interval)

    }, [loading])

    return(
        <AuthContext.Provider value={contextData} >
            {loading ? null : children}
        </AuthContext.Provider>
    )
}
