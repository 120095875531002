// PaymentForm.js

import { Button } from '@material-ui/core';
import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';

function PaymentForm({subscriptionid}) {
    let {authTokens, serverurl, setPaymentmodal, updateToken} = useContext(AuthContext);
    const razorpayKey = 'rzp_test_xjKylJWz4tbBv1';
    const authbearer = {'Authorization':'Bearer ' + String(authTokens.access)}

    const handlePayment = async () => {
        try {
            setPaymentmodal(false);
            const response = await fetch(serverurl+`/create_order/?plan_id=${subscriptionid}`,
                {headers:authbearer});
            const data = await response.json();
            
            if (data.order_id) {
                const options = {
                key: razorpayKey,
                amount: data.price,
                currency: 'INR',
                image: '/favicon.ico',
                color: '#006A4E',
                backdrop_color: '#006A4E',
                order_id: data.order_id,
                name: 'CausalityArrow',
                description: 'Test payment',
                handler: response => {
                    // Handle the payment success/failure on the backend
                    if (response.error) {
                        console.log('Payment Failed:', response.error);
                        // Handle failed payment logic
                      } else {
                        fetch(serverurl+'/handle_payment/', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 
                        'Authorization':'Bearer ' + String(authTokens.access)},
                        body: JSON.stringify({
                            payment_id: response.razorpay_payment_id,
                            order_id: response.razorpay_order_id,
                            signature: response.razorpay_signature,
                            plan_id: subscriptionid
                        })
                        })
                        .then(response => {
                            response.json();
                            updateToken();
                        })
                        .catch(error => {
                            console.log(error);
                        });
                        
                    }
                }
                };
                
                let rzp = new window.Razorpay(options);
                rzp.open();
            }
        } 
        catch(error) {
            console.log(error);
        }
    };

    return (
    <div>
        <Button style={{backgroundColor: 'darkblue',
        color: 'white'}} onClick={handlePayment}>
            Subscribe
        </Button>
    </div>
    );
    }

export default PaymentForm;
