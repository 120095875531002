import { useContext, useEffect, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import AuthContext from '../context/AuthContext';

const NewsImpact = ({id,symbol}) => {
    let {authTokens, serverurl} = useContext(AuthContext);
    const [impactsummary, setImpactsummary] = useState({"resp":"Thinking ...."});
    const [key, setKey] = useState(0);
    
    const authbearer = {'Authorization':'Bearer ' + String(authTokens.access)};

    const extractSymbol = (str) => {
      const parts = str.split(':');
      return parts.length > 1 ? parts[1] : str;
    };

    const cardimpact = async () => {
        const res = await fetch(serverurl+`/cardimpact/`+id+`/`+extractSymbol(symbol)+'/',{headers:authbearer});
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        else {
          const aa = await res.json();
          setImpactsummary(aa);
          setKey(prevKey => prevKey + 1);
        }
      }

    useEffect(() => {
        cardimpact();
    }, []);

    return <div>
              <TypeAnimation key={key} sequence={[impactsummary["resp"]]}
                    speed={90} style={{ fontSize: '1em' }} />
          </div>

}

export default NewsImpact;
  